/* eslint-disable react/no-array-index-key */
import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ScoreBubble from 'reports/base/charts/score-bubble';
import { formatMoney, formatPercentage } from 'utils/utils';
import {
  RECOMMENDED_TYPE,
  TARGET_TYPE,
  getEntitiesFromProposal,
  getProcessedMatchingModels
} from '../utils';
import styles from './styles';

const MatchingModelsChart = ({ proposal }) => {
  const accounts = getEntitiesFromProposal(proposal, TARGET_TYPE);
  const models = getEntitiesFromProposal(proposal, RECOMMENDED_TYPE);

  const hasModels = !_.isEmpty(models);

  const targetName = proposal?.target_label || '';
  const recommendedName = proposal?.recommended_label || '';

  const withPercentages = proposal.recommended_with_percentages;
  const withTargetBreakdown = proposal.target_breakdown;

  const {
    accountsOverallScore,
    accountsTotalAmount,
    modelsOverallScore,
    modelsTotalValue,
    groups
  } = getProcessedMatchingModels(accounts, models, proposal, withPercentages, withTargetBreakdown);

  const renderGroupRows = (elements, type, maxNumberOfElements) =>
    Array.from(Array(maxNumberOfElements)).map((e, i) => {
      const element = elements[i];

      if (element && (type === TARGET_TYPE || type === RECOMMENDED_TYPE)) {
        const { amount, display_name: name, prism_overall: score, weight } = element;
        return (
          <View
            key={`${name.toLowerCase().replace(/\s/g, '-')}-${score}-${amount}-${weight}`}
            style={styles.contentWrapper}
            wrap={false}
          >
            <View style={styles.contentScore}>
              <ScoreBubble score={score} />
            </View>
            <View style={[styles.contentLabel, { marginRight: 1 }]}>
              <Text style={[styles.label, { display: 'flex' }]}>{name}</Text>
            </View>
            <View style={[styles.contentAmount, { marginRight: 1 }]}>
              <Text>{formatMoney(amount)}</Text>
            </View>
            <View style={styles.contentWeight}>
              <Text>{formatPercentage(weight, 100, 2)}</Text>
            </View>
          </View>
        );
      }

      return (
        <View key={`empty-${type}-${i}`} style={styles.contentWrapper} wrap={false}>
          <View style={styles.contentScore} />
          <View style={[styles.contentLabel, { marginRight: 1 }]} />
          <View style={[styles.contentAmount, { marginRight: 1 }]} />
          <View style={styles.contentWeight} />
        </View>
      );
    });

  return (
    <View style={styles.table}>
      <View style={styles.row} wrap={false}>
        <View style={[styles.cell, styles.headerTargetCell]}>
          <Text style={styles.headerText}>{targetName || 'Current portfolio'}</Text>
        </View>
        {hasModels && (
          <View style={[styles.cell, styles.headerRecommendedCell]}>
            <Text style={styles.headerText}>{recommendedName || 'Suggested model'}</Text>
          </View>
        )}
      </View>

      {groups.map(({ accounts, models, maxNumberOfElements }, idx, arr) => {
        const rowStyles = [styles.row, styles.groupRow];
        if (arr.length - 1 === idx) rowStyles.push(styles.lastGroupRow);
        return (
          <View style={rowStyles}>
            <View style={styles.contentCell}>
              {renderGroupRows(accounts, TARGET_TYPE, maxNumberOfElements)}
            </View>
            {hasModels && (
              <View style={[styles.contentCell, { marginRight: 0 }]}>
                {renderGroupRows(models, RECOMMENDED_TYPE, maxNumberOfElements)}
              </View>
            )}
          </View>
        );
      })}

      <View style={styles.row} wrap={false}>
        <View style={styles.contentCell}>
          <View style={styles.contentWrapper}>
            <View style={[styles.contentScore, styles.totalTargetCell]}>
              <ScoreBubble score={accountsOverallScore} />
            </View>
            <View style={[styles.contentLabel, styles.totalTargetCell]}>
              <Text>Total</Text>
            </View>
            <View style={[styles.contentAmount, styles.totalTargetCell]}>
              <Text>{formatMoney(accountsTotalAmount)}</Text>
            </View>
            <View style={[styles.contentWeight, styles.totalTargetCell]}>
              <Text>100%</Text>
            </View>
          </View>
        </View>
        {hasModels && (
          <View style={[styles.contentCell, { marginRight: 0 }]}>
            <View style={styles.contentWrapper}>
              <View style={[styles.contentScore, styles.totalRecommendedCell]}>
                <ScoreBubble score={modelsOverallScore} />
              </View>
              <View style={[styles.contentLabel, styles.totalRecommendedCell]}>
                <Text>Overall</Text>
              </View>
              <View style={[styles.contentAmount, styles.totalRecommendedCell]}>
                <Text>{formatMoney(withPercentages ? accountsTotalAmount : modelsTotalValue)}</Text>
              </View>
              <View style={[styles.contentWeight, styles.totalRecommendedCell]}>
                <Text>100%</Text>
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

MatchingModelsChart.propTypes = {
  proposal: PropTypes.object.isRequired
};

export default MatchingModelsChart;
