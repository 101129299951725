import _ from 'lodash';

export const RECOMMENDED_TYPE = 'recommended';
export const TARGET_TYPE = 'target';

export const getEntitiesFromProposal = (proposal, type) => {
  if (!proposal[type]) return [];

  if (proposal[type].group_details) return proposal[type].group_details;

  const entity = {
    display_name: proposal[type].display_name,
    id: proposal[type].id,
    prism_overall: proposal[type].prism_score_summary.overall,
    weight: 100
  };
  if (type === TARGET_TYPE) entity.value = proposal[type].value;
  if (type === RECOMMENDED_TYPE && Number.isFinite(proposal[type]?.amount))
    entity.amount = proposal[type].amount;

  return [entity];
};

const byAccountAmount = (a, b) => a.amount - b.amount;
const byModelWeight = (a, b) => a.weight - b.weight;

export const getProcessedMatchingModels = (
  accounts,
  models,
  proposal,
  withPercentages,
  withTargetBreakdown
) => {
  const hasModels = !_.isEmpty(models);

  const accountsTotalAmount = accounts.reduce((acc, account) => acc + account.value, 0);
  const modelsTotalValue = proposal.recommended_total_value ?? 0;

  const accountsOverallScore = accounts.reduce(
    (acc, account) => acc + account.prism_overall * (account.value / accountsTotalAmount),
    0
  );
  const modelsOverallScore = hasModels
    ? models.reduce((acc, model) => acc + model.prism_overall * (model.weight / 100), 0)
    : 0;

  // if the proposal is simple (no `target_breakdown`), return the original accounts and models
  // along with the required total values
  if (!withTargetBreakdown) {
    const maxNumberOfElements = hasModels
      ? Math.max(accounts.length, models.length)
      : accounts.length;

    const processedAccounts = accounts
      .map(account => ({
        ...account,
        amount: account.value,
        weight: account.value / accountsTotalAmount
      }))
      .sort(byAccountAmount);
    const processedModels = models
      .map(model => {
        const weight = model.weight / 100;
        return {
          ...model,
          amount: withPercentages ? weight * accountsTotalAmount : model.amount ?? modelsTotalValue,
          weight
        };
      })
      .sort(byModelWeight);

    return {
      accountsOverallScore,
      accountsTotalAmount,
      modelsOverallScore,
      modelsTotalValue,
      groups: [{ accounts: processedAccounts, models: processedModels, maxNumberOfElements }]
    };
  }

  // if the proposal is advanced (with `target_breakdown`), it processes the accounts and models
  // by groups adjusting the percentages and amounts appropriately
  const targetGroups = withTargetBreakdown && proposal?.data?.target ? proposal.data.target : {};
  const recommendedGroups =
    withTargetBreakdown && proposal?.data?.recommended ? proposal.data.recommended : {};

  const groups = Object.keys(targetGroups).map(groupId => {
    const targetGroup = targetGroups[groupId];
    const recommendedGroup = recommendedGroups[groupId] || [];

    const targetGroupIds = targetGroup.map(portfolio => portfolio.value);
    const recommendedGroupIds = recommendedGroup.map(portfolio => portfolio.value);

    const groupAccountsTotalAmount = targetGroup.reduce((acc, account) => acc + account.amount, 0);
    const maxNumberOfElements = Math.max(targetGroup.length, recommendedGroup.length);

    const processedAccounts = accounts
      .filter(account => targetGroupIds.includes(account.id))
      .map(account => ({
        ...account,
        amount: account.value,
        weight: account.value / groupAccountsTotalAmount
      }))
      .sort(byAccountAmount);
    const processedModels = models
      .filter(model => recommendedGroupIds.includes(model.id))
      .map(model => {
        const portfolio = recommendedGroup.find(portfolio => portfolio.value === model.id);
        const weight = (portfolio?.weight ?? 0) / 100;
        const amount = withPercentages ? weight * groupAccountsTotalAmount : portfolio.amount;
        return { ...model, amount, weight };
      })
      .sort(byModelWeight);

    return { accounts: processedAccounts, models: processedModels, maxNumberOfElements };
  });

  return {
    accountsOverallScore,
    accountsTotalAmount,
    modelsOverallScore,
    modelsTotalValue,
    groups
  };
};
