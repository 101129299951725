/* eslint-disable no-underscore-dangle */
/* global STANDARD_DATE_FORMAT */
import cn from 'classnames';
import IPSReportViewer from 'components/advisor/ips/report/viewer';
import { MODE_URL_PARAM, READ_ONLY_MODE } from 'components/advisor/proposal/constants';
import 'components/advisor/proposal/header/common/form/styles.scss';
import { PROPOSAL_SELECT_STYLES } from 'components/advisor/proposal/header/common/form/utils';
import {
  convertPortfoliosAmountsToPercentages,
  getPortfolioSuggestionsDefault,
  handleStartingValueChange,
  handleYearlyWithdrawalAmountChange,
  handleYearlyWithdrawalRateChange,
  normalizeProposalPercentageValues
} from 'components/advisor/proposal/header/utils';
import WeightedPortfolioSelector from 'components/advisor/proposal/portfolio-selector/weighted';
import {
  SECURITY_TYPE,
  getAsyncPortfolioSuggestionsDefault
} from 'components/advisor/proposal/portfolio-selector/weighted/utils';
import ProposalReportViewer from 'components/advisor/proposal/report/viewer';
import { getRequiredSigners } from 'components/advisor/templates/sections/signature/digital-signature/utils';
import { FormGroup, VerboseErrorInput } from 'components/form';
import ReactDateRangePickerWithSelectors from 'components/form/date-range-picker/custom-range-with-selectors';
import ManagementFeeField from 'components/form/management-fee-field';
import InlineTextEditor from 'components/inline-text-editor';
import TooltipV2 from 'components/tooltip-v2';
import { AdvisorContext } from 'containers/advisor';
import {
  IPS_DEFAULT_TEMPLATE,
  PROPOSAL_DEFAULT_TEMPLATE
} from 'containers/advisor/templates/defaults';
import ReportOptionsToggleable from 'containers/advisor/templates/report-options-toggleable';
import { BackendValidation } from 'hocs/backend-validation';
import _ from 'lodash';
import moment from 'moment';
import {
  ARCHIVED,
  DRAFT,
  PUBLISHED,
  hasReportReviews,
  isReportReadOnly
} from 'pages/proposal-or-ips-reports/constants';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Select from 'react-select';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { trackAmplitudeEvent } from 'utils/tracking';
import { getReportUrl, getTargetInvestors, setPrecision } from 'utils/utils';
import {
  ARCHIVED_NOTIFICATION_VERB,
  CREATED_NOTIFICATION_VERB,
  DELETED_NOTIFICATION_VERB,
  PUBLISHED_NOTIFICATION_VERB,
  REPORTS_BASE_URL_ENDS_WITH_ID,
  REPORTS_BASE_URL_REGEX,
  REPORTS_COPY_SUFFIX,
  RESTORED_NOTIFICATION_VERB,
  SAVED_NOTIFICATION_VERB,
  UPDATED_NOTIFICATION_VERB
} from './constants';
import ProposalFormDeleteModal from './delete-modal';
import ProposalFormPublishModal from './publish-modal';
import ProposalFormRenameModal from './rename-modal';
import ProposalSignatureRequestModal from './signature-request-modal';
import SignatureRequestTrailInline from './signature-request-trail/inline';
import { TARGET_BREAKDOWN_ENABLED, reportNotification, validate } from './utils';
import ProposalFormAdvancedVersion from './version/advanced';
import ProposalFormSimpleVersion from './version/simple';

const ProposalForm = ({
  allowPrint,
  currentReport,
  currentReportSettings,
  entityId,
  error,
  fields,
  handleSubmit,
  initializeForm,
  invalid,
  investor,
  isIPS,
  loading,
  managers,
  onGenerate,
  onTargetChange,
  proposal,
  proposalType,
  reportUrl,
  scope,
  submitting,
  targetSuggestions,
  templateOptions,
  templateSuggestions
}) => {
  const {
    accountProvider,
    authProvider,
    ipsProvider,
    marketProvider,
    modelProvider,
    proposalProvider,
    routerActions,
    user
  } = useContext(AdvisorContext);

  const [asyncSuggestions, setAsyncSuggestions] = useState([]);
  const [benchmarkTotalValue, setBenchmarkTotalValue] = useState(100);
  const [creatingReport, setCreatingReport] = useState(false);
  const [initializedForm, setInitializedForm] = useState(false);
  const [recommendedTotalValue, setRecommendedTotalValue] = useState(100);
  const [requiresAsyncSuggestions, setRequiresAsyncSuggestions] = useState(true);
  const [requiresFormInitialization, setRequiresFormInitialization] = useState(false);
  const [updatingReport, setUpdatingReport] = useState(false);
  const [uploadingReport, setUploadingReport] = useState(false);

  const provider = isIPS ? ipsProvider : proposalProvider;

  useEffect(() => {
    const requiresFormInitialization = REPORTS_BASE_URL_ENDS_WITH_ID.test(window.location.pathname);
    if (fields.recommended.initialValue.length === 0) setRecommendedTotalValue(0);
    else if (
      !requiresFormInitialization &&
      !!fields.recommended.initialValue.length &&
      !fields.recommendedWithPercentages.initialValue
    )
      // allows defining the total amount of the recommended models if it's determined that
      // initially, it's required because there are associated matching models
      setRecommendedTotalValue(
        fields.recommended.initialValue.reduce((acc, model) => acc + model.amount, 0)
      );
    setRequiresFormInitialization(requiresFormInitialization);
  }, []);

  useEffect(() => {
    if (proposal && requiresAsyncSuggestions && !asyncSuggestions.length) {
      setRequiresAsyncSuggestions(false);
      getAsyncPortfolioSuggestionsDefault(
        proposal,
        accountProvider,
        marketProvider,
        modelProvider
      ).then(suggestions => {
        setAsyncSuggestions(suggestions.flat());
      });
    }
  }, [asyncSuggestions.length, requiresAsyncSuggestions, JSON.stringify(proposal)]);

  useEffect(() => {
    if (
      proposal &&
      requiresFormInitialization &&
      !initializedForm &&
      !!targetSuggestions.length &&
      !!asyncSuggestions.length
    ) {
      setInitializedForm(true);

      const { suggestionsDefault: target } = getPortfolioSuggestionsDefault(
        proposal,
        targetSuggestions,
        'target'
      );

      let recommended;
      if (proposal.recommended) {
        const {
          totalValue: recommendedTotalValue,
          suggestionsDefault: recommendedSuggestionsDefault
        } = getPortfolioSuggestionsDefault(proposal, asyncSuggestions, 'recommended');
        setRecommendedTotalValue(recommendedTotalValue);
        recommended = recommendedSuggestionsDefault;
      }

      let benchmark;
      if (proposal.benchmark) {
        const { totalValue: benchmarkTotalValue, suggestionsDefault: benchmarkSuggestionsDefault } =
          getPortfolioSuggestionsDefault(proposal, asyncSuggestions, 'benchmark');
        setBenchmarkTotalValue(benchmarkTotalValue);
        benchmark = benchmarkSuggestionsDefault;
      }

      const values = {
        period: [proposal.start, proposal.end],
        recommended,
        recommendedLabel: proposal.recommended_label,
        recommendedWithPercentages: proposal.recommended_with_percentages,
        startingValue: proposal.starting_value,
        target,
        targetLabel: proposal.target_label,
        template: proposal.template.id
      };

      if (!isIPS) {
        values.benchmark = benchmark;
        values.benchmarkLabel = proposal.benchmark_label;
        values.benchmarkManagementFee = (proposal.benchmark_management_fee * 100).toFixed(2);
        values.benchmarkWithPercentages = proposal.benchmark_with_percentages;
        values.data = proposal.data;
        values.recommendedManagementFee = (proposal.recommended_management_fee * 100).toFixed(2);
        values.targetBreakdown = proposal.target_breakdown;
        values.targetManagementFee = (proposal.target_management_fee * 100).toFixed(2);
        values.yearlyWithdrawalAmount = String(
          proposal.starting_value * proposal.yearly_withdrawal_rate
        );
        values.yearlyWithdrawalRate = (proposal.yearly_withdrawal_rate * 100).toFixed(2);
      }

      initializeForm(values);
    }
  }, [
    asyncSuggestions.length,
    targetSuggestions.length,
    requiresFormInitialization,
    JSON.stringify(proposal)
  ]);

  const handleTrackAmplitudeEvent = event => {
    const params = {
      id: entityId,
      investor: investor.id,
      is_prospect: investor.is_prospect,
      with_recommended: !!proposal.recommended
    };
    if (!isIPS) params.with_benchmark = !!proposal.benchmark;
    trackAmplitudeEvent(event, params);
  };

  const handleBenchmarkChange = portfolios => {
    if (!portfolios) portfolios = [];
    setBenchmarkTotalValue(
      setPrecision(
        portfolios.reduce((acc, b) => acc + b.weight, 0),
        2
      )
    );
    fields.benchmark.onChange(portfolios);
  };

  const handleReportCreation = verb => name => {
    setCreatingReport(true);

    const data = { name, [isIPS ? 'ips' : 'proposal']: entityId, settings: currentReportSettings };
    const query = new URLSearchParams(window.location.search);
    const isReadOnly =
      query.get(MODE_URL_PARAM) === READ_ONLY_MODE || isReportReadOnly(currentReport?.status);

    const amplitudeEvents = {
      saved: isIPS ? 'ips.saved' : 'proposal.saved',
      created: isIPS ? 'ips.duplicated' : 'proposal.duplicated'
    };
    handleTrackAmplitudeEvent(amplitudeEvents[verb]);

    return provider
      .createReport(data, investor.id, investor.is_prospect)
      .then(({ data: report }) => {
        reportNotification(report.name, verb);
        setUploadingReport(true);
        provider
          .uploadPdfReport(
            isReadOnly ? getReportUrl(currentReport) : reportUrl,
            report.id,
            investor.id,
            investor.is_prospect
          )
          .finally(() => {
            setUploadingReport(false);
          });
        const url = window.location.pathname.replace(REPORTS_BASE_URL_REGEX, `/${report.id}`);
        routerActions.push(url);
      })
      .finally(() => {
        setCreatingReport(false);
      });
  };

  const handleReportUpdate = (data, verb = null, celebration = true) => {
    setUpdatingReport(true);

    if (currentReport)
      provider
        .updateReport(data, currentReport.id, investor.id, investor.is_prospect)
        .then(({ data: report }) => {
          if (verb) reportNotification(report.name, verb, celebration);
          provider.setCurrentReportUnsaved(false);
        })
        .finally(() => {
          setUpdatingReport(false);
        });
  };

  const handleReportDelete = async () => {
    const reportsUrl = window.location.pathname.replace(REPORTS_BASE_URL_REGEX, '');

    if (currentReport) {
      const amplitudeEvent = isIPS ? 'ips.deleted' : 'proposal.deleted';
      handleTrackAmplitudeEvent(amplitudeEvent);

      await provider.deleteReport(currentReport.id, investor.id, investor.is_prospect).then(() => {
        reportNotification(currentReport.name, DELETED_NOTIFICATION_VERB, false);
        routerActions.push(reportsUrl);
      });
    }
  };

  const handleSaveTemplateContent = async content =>
    provider.update(proposal.id, { template_content: content }).then(() => {
      if (currentReport) provider.setCurrentReportUnsaved(true);
    });

  const handleSaveTemplate = async (_, content) => handleSaveTemplateContent(content);

  const handleSaveTemplateOrder = async (content, _) => handleSaveTemplateContent(content);

  const handleEditReport = () => {
    const editReportUrl = new URL(window.location.pathname, window.location.origin);
    routerActions.push(editReportUrl);
  };

  const handleReportNameChange = name => {
    handleReportUpdate({ name });
  };

  const handleReportReferenceChange = () => {
    handleReportUpdate(
      { [isIPS ? 'ips' : 'proposal']: entityId, settings: currentReportSettings },
      UPDATED_NOTIFICATION_VERB
    );
    setUploadingReport(true);
    provider
      .uploadPdfReport(reportUrl, currentReport.id, investor.id, investor.is_prospect)
      .finally(() => {
        setUploadingReport(false);
      });
  };

  const handleReportStatusChange = status => () => {
    const source = isIPS ? 'ips' : 'proposal';
    const notificationVerbs = {
      [ARCHIVED]: ARCHIVED_NOTIFICATION_VERB,
      [DRAFT]: RESTORED_NOTIFICATION_VERB,
      [PUBLISHED]: PUBLISHED_NOTIFICATION_VERB
    };
    const amplitudeEvents = {
      [ARCHIVED]: `${source}.archived`,
      [DRAFT]: `${source}.restored`,
      [PUBLISHED]: `${source}.published`
    };

    handleTrackAmplitudeEvent(amplitudeEvents[status]);
    handleReportUpdate({ status }, notificationVerbs[status], status !== ARCHIVED);
  };

  const onSubmit = async values => {
    // avoids submitting and creating a new proposal when it's already in read-only
    // mode and requesting the client signatures
    if (isReportReadOnly(currentReport?.status)) return null;

    normalizeProposalPercentageValues(values);

    if (!fields.recommendedWithPercentages.value) {
      values.recommended_total_value = Number.parseFloat(recommendedTotalValue.toFixed(2));
      values.recommended = convertPortfoliosAmountsToPercentages(values.recommended);
    }
    if (!fields.benchmarkWithPercentages.value && !isIPS) {
      values.benchmark_total_value = Number.parseFloat(benchmarkTotalValue.toFixed(2));
      values.benchmark = convertPortfoliosAmountsToPercentages(values.benchmark);
    }

    return onGenerate(values);
  };

  const reportType = lower => {
    if (isIPS) return 'IPS';
    return lower ? 'proposal' : 'Proposal';
  };

  const toggleBenchmarkUnit = () => {
    fields.benchmarkWithPercentages.onChange(!fields.benchmarkWithPercentages.value);
  };

  const toggleTargetBreakdown = () => {
    fields.targetBreakdown.onChange(!fields.targetBreakdown.value);
    if (window.localStorage)
      window.localStorage.setItem(TARGET_BREAKDOWN_ENABLED, !fields.targetBreakdown.value);
  };

  const investors = proposal ? getTargetInvestors(proposal) : [];
  const isComplianceOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);
  const isDraftReport = window.location.pathname.includes('new');
  const isInvalidTemplate = templateOptions.find(
    el => !el.approved && el.id === fields.template.value
  );
  const reportActionDisabled = updatingReport || creatingReport || submitting || !allowPrint;
  const reportsUrl = window.location.pathname.replace(REPORTS_BASE_URL_REGEX, '');
  const templateContent =
    proposal?.template_content ||
    proposal?.template?.content ||
    (isIPS ? IPS_DEFAULT_TEMPLATE : PROPOSAL_DEFAULT_TEMPLATE);
  const unchangedReport =
    (currentReport?.proposal === entityId || currentReport?.ips === entityId) &&
    !currentReport?.unsaved;

  const requiredSigners = getRequiredSigners(templateContent, managers, investors);
  const hasRequestSignaturePermissions =
    user?.advisor?.company?.digital_signature_enabled && !_.isEmpty(requiredSigners);

  const canOrderTemplate =
    proposal?.id && (isComplianceOrAbove || proposal?.template?.settings?.allowSectionsOrder);
  const query = new URLSearchParams(window.location.search);
  const isReadOnly =
    query.get(MODE_URL_PARAM) === READ_ONLY_MODE || isReportReadOnly(currentReport?.status);

  return (
    <>
      <div className="proposal-form-breadcrumbs">
        <div className="proposal-form-breadcrumbs__title">
          <Link to={reportsUrl}>
            {isIPS ? 'IPS' : 'Proposals'} for {investor.full_name}
          </Link>{' '}
          &gt;{' '}
          {isDraftReport ? (
            'Draft'
          ) : (
            <InlineTextEditor
              onChange={handleReportNameChange}
              text={currentReport?.name}
              updating={updatingReport}
            />
          )}
          {currentReport && (
            <div className={cn('report-badge', `report-badge--${currentReport.status}`)}>
              {currentReport.status.replace(/_/g, ' ')}
            </div>
          )}
        </div>
        {!isIPS && (
          <div className="proposal-form-breadcrumbs__actions">
            <label htmlFor="target-breakdown">Accounts Breakdown</label>
            <Toggle
              checked={!!fields.targetBreakdown.value}
              className="toggle-primary"
              id="toggle-target-breakdown"
              onChange={toggleTargetBreakdown}
            />
          </div>
        )}
      </div>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="proposal-form">
        {!isReadOnly && (
          <div
            className={cn('steps', {
              'less-opacity':
                loading || isReadOnly || (requiresFormInitialization && !initializedForm)
            })}
          >
            {!fields.targetBreakdown.value && (
              <ProposalFormSimpleVersion
                fields={fields}
                isIPS={isIPS}
                onTargetChange={onTargetChange}
                proposalType={proposalType}
                scope={scope}
                setRecommendedTotalValue={setRecommendedTotalValue}
                targetSuggestions={targetSuggestions}
              />
            )}

            {fields.targetBreakdown.value &&
              (!requiresFormInitialization || initializedForm ? (
                <ProposalFormAdvancedVersion
                  fields={fields}
                  isIPS={isIPS}
                  onTargetChange={onTargetChange}
                  proposalType={proposalType}
                  scope={scope}
                  setRecommendedTotalValue={setRecommendedTotalValue}
                  targetSuggestions={targetSuggestions}
                />
              ) : (
                <>
                  <div className="step">
                    <div className="step__container">Loading ...</div>
                  </div>
                  <div className="step">
                    <div className="step__container">Loading ...</div>
                  </div>
                </>
              ))}

            {!isIPS && (
              <div className="step">
                <div className="step__container" data-ips={isIPS}>
                  <div className="benchmark">
                    <div className="benchmark__label">
                      <label htmlFor="benchmark">Select a benchmark (optional)</label>
                      <Toggle
                        className="toggle-unit"
                        icons={{
                          checked: <span className="toggle-unit__icon">%</span>,
                          unchecked: <span className="toggle-unit__icon">$</span>
                        }}
                        id="toggle-benchmark-unit"
                        onChange={toggleBenchmarkUnit}
                        checked={!!fields.benchmarkWithPercentages.value}
                      />
                    </div>
                    <WeightedPortfolioSelector
                      defaultValues={fields.benchmark.initialValue}
                      key="benchmark-weighted-portfolio"
                      onChange={handleBenchmarkChange}
                      scope={scope}
                      type={SECURITY_TYPE}
                      withPercentages={!!fields.benchmarkWithPercentages.value}
                    />
                  </div>

                  <div>
                    <ManagementFeeField field={fields.benchmarkManagementFee} />
                    <div className="benchmark-label">
                      <label htmlFor="benchmark-label">Customize label (optional)</label>
                      <VerboseErrorInput
                        name="benchmark-label"
                        type="text"
                        {...fields.benchmarkLabel}
                        placeholder="Benchmark"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="step">
              <div className="step__last-container" data-ips={isIPS}>
                <div className="template">
                  <label htmlFor="template">Template *</label>
                  <Select
                    className="options"
                    onChange={fields.template.onChange}
                    options={templateSuggestions}
                    styles={PROPOSAL_SELECT_STYLES}
                    value={templateOptions.find(t => t.value === fields.template.value)}
                  />
                  {!!(fields.template.touched && fields.template.error) && (
                    <div className="text-danger" style={{ marginTop: 10, fontSize: 12 }}>
                      {fields.template.error}
                    </div>
                  )}
                </div>
                {!isIPS && (
                  <>
                    <FormGroup {...fields.startingValue} className="starting-value form-group">
                      <VerboseErrorInput
                        {...fields.startingValue}
                        className="form-control"
                        fieldsetClassName="form-group-money"
                        label="Starting value *"
                        placeholder="Starting value"
                        type="text"
                        onChange={handleStartingValueChange(
                          fields.startingValue,
                          fields.yearlyWithdrawalAmount,
                          fields.yearlyWithdrawalRate
                        )}
                      />
                    </FormGroup>

                    <FormGroup {...fields.period} className="start form-group">
                      <VerboseErrorInput {...fields.period} label="Period *">
                        <ReactDateRangePickerWithSelectors
                          error={fields.period.error}
                          feedbackMessage="Minimum 9 months required"
                          onChange={fields.period.onChange}
                          value={fields.period.value}
                        />
                      </VerboseErrorInput>
                    </FormGroup>

                    <FormGroup
                      {...fields.yearlyWithdrawalRate}
                      className="yearly-withdrawal form-group"
                    >
                      <label htmlFor="yearly-withdrawal">Yearly Withdrawal *</label>
                      <div>
                        <VerboseErrorInput
                          {...fields.yearlyWithdrawalRate}
                          className="form-control yearly-withdrawal__percent"
                          fieldsetClassName="form-group-percentage"
                          type="text"
                          onChange={handleYearlyWithdrawalRateChange(
                            fields.startingValue,
                            fields.yearlyWithdrawalAmount,
                            fields.yearlyWithdrawalRate
                          )}
                        />
                        <VerboseErrorInput
                          {...fields.yearlyWithdrawalAmount}
                          className="form-control yearly-withdrawal__amount"
                          fieldsetClassName="form-group-money"
                          type="text"
                          onChange={handleYearlyWithdrawalAmountChange(
                            fields.startingValue,
                            fields.yearlyWithdrawalAmount,
                            fields.yearlyWithdrawalRate
                          )}
                        />
                      </div>
                    </FormGroup>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {!isReadOnly && error && <p className="text-danger">{error}</p>}

        {!isReadOnly && !submitting && proposal && (
          <ReportOptionsToggleable
            canOrder={canOrderTemplate}
            canUpdate
            handleSaveTemplate={handleSaveTemplate}
            handleSaveTemplateOrder={handleSaveTemplateOrder}
            templateContent={templateContent}
          />
        )}

        <div className="form-actions">
          <div className="form-actions__generation">
            {!isReadOnly && !submitting && (
              <button
                type="submit"
                disabled={
                  isReadOnly ||
                  submitting ||
                  loading ||
                  invalid ||
                  (requiresFormInitialization && !initializedForm) ||
                  (fields.recommendedWithPercentages.value &&
                    recommendedTotalValue !== 0 &&
                    recommendedTotalValue !== 100) ||
                  (recommendedTotalValue === 0 && !_.isEmpty(fields.recommended.value)) ||
                  (fields.benchmarkWithPercentages.value &&
                    benchmarkTotalValue !== 0 &&
                    benchmarkTotalValue !== 100) ||
                  (benchmarkTotalValue === 0 && !_.isEmpty(fields.benchmark.value))
                }
                className="btn btn-primary"
              >
                Generate
              </button>
            )}

            {isReadOnly && !hasReportReviews(currentReport?.status) && (
              <TooltipV2
                place="bottom"
                id="edit-report-tooltip"
                label={
                  isReportReadOnly(currentReport?.status)
                    ? `This ${reportType(true)} is published. It cannot be edited`
                    : `Continue editing this ${reportType(true)}`
                }
              >
                <div data-for="edit-report-tooltip" data-tip="">
                  <button
                    type="button"
                    onClick={handleEditReport}
                    className="btn btn-primary"
                    disabled={isReportReadOnly(currentReport?.status)}
                  >
                    Continue Editing
                  </button>
                </div>
              </TooltipV2>
            )}

            {user?.advisor?.company?.digital_signature_enabled &&
              hasReportReviews(currentReport?.status) && (
                <SignatureRequestTrailInline reviews={currentReport.reviews} />
              )}

            {!isReadOnly && isIPS && !submitting && allowPrint && (
              <IPSReportViewer
                directDownload={isReadOnly}
                isInvalidTemplate={isInvalidTemplate}
                subtitle={investor.full_name}
              />
            )}

            {!isReadOnly && !isIPS && !submitting && (
              <ProposalReportViewer
                directDownload={isReadOnly}
                isInvalidTemplate={isInvalidTemplate}
                subtitle={investor.full_name}
              />
            )}
          </div>

          <div className="form-actions__saving">
            {isDraftReport && entityId && (
              <ProposalFormRenameModal
                buttonClassName="btn-primary"
                buttonDisabled={reportActionDisabled || invalid}
                buttonText={creatingReport ? 'Saving...' : 'Save'}
                defaultName={`${reportType()} for ${investor.full_name} - ${moment().format(
                  STANDARD_DATE_FORMAT
                )}`}
                isIPS={isIPS}
                isReadOnly={isReadOnly}
                process={handleReportCreation(SAVED_NOTIFICATION_VERB)}
                reportOrientation={currentReportSettings.orientation}
                reportType={reportType(true)}
                reportUrl={isReadOnly ? getReportUrl(currentReport) : reportUrl}
                tooltipId="save-report"
              />
            )}

            {!isDraftReport && entityId && (
              <>
                {currentReport?.status === DRAFT && (
                  <ProposalFormPublishModal
                    buttonDisabled={uploadingReport}
                    label={currentReport?.name}
                    onPublish={handleReportStatusChange(PUBLISHED)}
                    reportType={reportType(true)}
                    title={`Publish ${reportType()}?`}
                    tooltipText={`Publish the current ${reportType(true)}`}
                  />
                )}

                {(currentReport?.status === DRAFT || currentReport?.status === PUBLISHED) &&
                  hasRequestSignaturePermissions && (
                    <ProposalSignatureRequestModal
                      buttonDisabled={uploadingReport}
                      isIPS={isIPS}
                      reportId={currentReport.id}
                      reportInvestor={investor}
                      reportStatus={currentReport.status}
                      requiredSigners={requiredSigners}
                      title={`Request ${reportType()} Review & Signature`}
                    />
                  )}

                {!isReadOnly && currentReport?.status === DRAFT && (
                  <TooltipV2
                    place="bottom"
                    id="update-report-tooltip"
                    label={
                      unchangedReport
                        ? 'There are no changes to save'
                        : `Update the ${reportType(true)} report with the latest changes`
                    }
                  >
                    <div data-for="update-report-tooltip" data-tip="">
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={unchangedReport || reportActionDisabled || invalid}
                        onClick={handleReportReferenceChange}
                      >
                        {updatingReport ? 'Saving...' : 'Save'}
                      </button>
                    </div>
                  </TooltipV2>
                )}

                <ProposalFormRenameModal
                  buttonClassName="btn-outline-primary"
                  buttonDisabled={reportActionDisabled}
                  buttonText={updatingReport ? 'Saving as...' : 'Save as'}
                  defaultName={`${currentReport?.name} ${REPORTS_COPY_SUFFIX}`}
                  isIPS={isIPS}
                  isReadOnly={isReadOnly}
                  process={handleReportCreation(CREATED_NOTIFICATION_VERB)}
                  reportOrientation={currentReportSettings.orientation}
                  reportType={reportType(true)}
                  reportUrl={isReadOnly ? getReportUrl(currentReport) : reportUrl}
                  tooltipId="save-as-new-report"
                  tooltipText={`Create a copy of the current ${reportType(true)} report`}
                />

                {currentReport?.status === DRAFT && (
                  <TooltipV2
                    place="bottom"
                    id="archive-report-tooltip"
                    label={`Archive the current ${reportType(true)} report`}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      disabled={reportActionDisabled}
                      onClick={handleReportStatusChange(ARCHIVED)}
                      data-for="archive-report-tooltip"
                      data-tip=""
                    >
                      Archive
                    </button>
                  </TooltipV2>
                )}

                {currentReport?.status === ARCHIVED && (
                  <TooltipV2
                    place="bottom"
                    id="restore-report-tooltip"
                    label={`Restore the current ${reportType(true)} report`}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      disabled={reportActionDisabled}
                      onClick={handleReportStatusChange(DRAFT)}
                      data-for="restore-report-tooltip"
                      data-tip=""
                    >
                      Restore
                    </button>
                  </TooltipV2>
                )}

                {(currentReport?.status === DRAFT || currentReport?.status === ARCHIVED) && (
                  <ProposalFormDeleteModal
                    label={currentReport?.name}
                    onDelete={handleReportDelete}
                    title={isIPS ? 'Delete IPS?' : 'Delete Proposal?'}
                  />
                )}
              </>
            )}
          </div>
        </div>

        {!isReadOnly && allowPrint && isInvalidTemplate && (
          <p style={{ textAlign: 'right', fontSize: '0.8rem' }}>
            To generate a PDF Report the selected template must be approved by an administrative
            role first
          </p>
        )}
      </form>
    </>
  );
};

ProposalForm.defaultProps = {
  allowPrint: false,
  currentReport: null,
  entityId: null,
  error: null,
  fields: {},
  investor: {},
  onGenerate: () => {},
  proposal: null,
  reportUrl: null,
  templateOptions: [],
  templateSuggestions: []
};

ProposalForm.propTypes = {
  allowPrint: PropTypes.bool,
  currentReport: PropTypes.object,
  currentReportSettings: PropTypes.object.isRequired,
  entityId: PropTypes.number,
  error: PropTypes.string,
  fields: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  initializeForm: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  investor: PropTypes.object,
  ips: PropTypes.object.isRequired,
  isIPS: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  managers: PropTypes.array.isRequired,
  onGenerate: PropTypes.func,
  onTargetChange: PropTypes.func.isRequired,
  proposal: PropTypes.object,
  proposalType: PropTypes.string.isRequired,
  reportUrl: PropTypes.string,
  scope: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  targetSuggestions: PropTypes.array.isRequired,
  templateOptions: PropTypes.array,
  templateSuggestions: PropTypes.array
};

export default compose(
  connect((state, props) => ({
    currentReport: props.isIPS ? state.ips.currentReport : state.proposals.currentReport,
    currentReportSettings: props.isIPS
      ? state.ips.currentReportSettings
      : state.proposals.currentReportSettings,
    form: props.isIPS ? 'generateIPS' : 'generateProposal',
    ips: state.ips.view,
    proposal: props.isIPS ? state.ips.view?.proposal : state.proposals.view,
    reportUrl: props.isIPS ? state.ips.url : state.proposals.url
  })),
  reduxForm({
    touchOnChange: true,
    fields: [
      'benchmark',
      'benchmarkLabel',
      'benchmarkManagementFee',
      'benchmarkWithPercentages',
      'data',
      'period',
      'recommended',
      'recommendedLabel',
      'recommendedManagementFee',
      'recommendedWithPercentages',
      'startingValue',
      'target',
      'targetBreakdown',
      'targetLabel',
      'targetManagementFee',
      'template',
      'yearlyWithdrawalAmount',
      'yearlyWithdrawalRate'
    ],
    validate,
    overwriteOnInitialValuesChange: false
  })
)(BackendValidation(ProposalForm));
