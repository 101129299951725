/* eslint-disable react/no-array-index-key */
import { DEFAULT_STARTING_VALUE } from 'components/advisor/proposal/header/utils';
import { VerboseErrorInput } from 'components/form';
import ManagementFeeField from 'components/form/management-fee-field';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { setPrecision } from 'utils/utils';
import {
  hasInitialValues,
  HOUSEHOLD_LABEL,
  initializeGroups,
  processRecommendedGroups,
  setRecommendedGroupTargets
} from '../utils';
import ProposalFormGroup from './group';

const ProposalFormAdvancedVersion = ({
  fields,
  isIPS,
  onTargetChange,
  proposalType,
  scope,
  setRecommendedTotalValue,
  targetSuggestions
}) => {
  const [groups, setGroups] = useState([]);
  const [householdOption, setHouseholdOption] = useState(null);
  const [recommendedGroups, setRecommendedGroups] = useState({});
  const [requiresInitialization, setRequiresInitialization] = useState(true);
  const [targetGroups, setTargetGroups] = useState({});

  const addGroup = () => {
    const groupId = Math.random().toString(36).substring(2);
    setGroups(prevGroups => [...prevGroups, groupId]);
    return groupId;
  };

  const removeGroup = id => {
    setGroups(prevGroups => prevGroups.filter(groupId => groupId !== id));

    // TODO:
    // if the target group being deleted is the one that has all the household accounts,
    // it does not restore the entry itself, only the associated accounts
    if (targetGroups[id])
      setTargetGroups(prevTargetGroups => {
        const { [id]: _, ...restTargetGroups } = prevTargetGroups;
        return restTargetGroups;
      });

    if (recommendedGroups[id])
      setTargetGroups(prevRecommendedGroups => {
        const { [id]: _, ...restRecommendedGroups } = prevRecommendedGroups;
        return restRecommendedGroups;
      });
  };

  const handleRecommendedChange = groupId => portfolios => {
    if (!portfolios) portfolios = [];

    const targetGroup = targetGroups[groupId] || [];
    const recommendedGroupPortfolios = setRecommendedGroupTargets(portfolios, targetGroup);

    setRecommendedGroups(prevRecommendedGroups => ({
      ...prevRecommendedGroups,
      [groupId]: recommendedGroupPortfolios
    }));
  };

  const handleTargetChange = groupId => (portfolios, actionMeta) => {
    if (!portfolios) portfolios = [];

    // evaluates the household to add the associated accounts
    if (actionMeta.option && actionMeta.option.isFullHousehold) {
      const accountOptions = targetSuggestions.filter(element => element.label !== HOUSEHOLD_LABEL);
      const householdOption = targetSuggestions.find(element => element.label === HOUSEHOLD_LABEL);
      if (householdOption) {
        // sets the household option
        setHouseholdOption(householdOption);

        // sets household accounts into the `options` attribute
        const householdPortfolios = actionMeta.option.suggestions;
        const householdOptionWithPortfolios = { ...householdOption, options: householdPortfolios };

        // sets selected targets as the current investor accounts + the household accounts
        const filteredPortfolios = portfolios.filter(element => !element.isFullHousehold);
        const selectedPortfolios = [...filteredPortfolios, ...householdPortfolios];
        onTargetChange(selectedPortfolios, [...accountOptions, householdOptionWithPortfolios]);
        setTargetGroups(prevTargetGroups => ({
          ...prevTargetGroups,
          [groupId]: selectedPortfolios
        }));
      }
    } else {
      let currentOptions = null;

      // allows deleting accounts belonging to the household
      if (actionMeta.action === 'remove-value' && actionMeta.removedValue.belongsToHouseholdGroup) {
        const householdPortfolios = portfolios
          ? portfolios.filter(element => element.belongsToHouseholdGroup)
          : [];
        if (!householdPortfolios.length) {
          const accountOptions = targetSuggestions.filter(
            element => element.label !== HOUSEHOLD_LABEL
          );
          currentOptions = [...accountOptions, householdOption];
        }
      }

      onTargetChange(portfolios, currentOptions);
      setTargetGroups(prevTargetGroups => ({ ...prevTargetGroups, [groupId]: portfolios }));
    }
  };

  const toggleRecommendedUnit = () => {
    fields.recommendedWithPercentages.onChange(!fields.recommendedWithPercentages.value);
  };

  useEffect(() => {
    if (
      hasInitialValues(fields.target.initialValue) &&
      hasInitialValues(fields.recommended.initialValue)
    ) {
      const { initialRecommendedGroups, initialTargetGroups } = initializeGroups(
        fields.target,
        fields.recommended,
        fields.data,
        addGroup
      );
      setTargetGroups(initialTargetGroups);
      setRecommendedGroups(initialRecommendedGroups);
      fields.data.onChange({ target: initialTargetGroups, recommended: initialRecommendedGroups });
    } else {
      fields.data.onChange({});
      addGroup();
    }
    setRequiresInitialization(false);
  }, []);

  useEffect(() => {
    if (requiresInitialization) return;
    const portfolios = processRecommendedGroups(
      targetGroups,
      recommendedGroups,
      fields.recommendedWithPercentages.value
    );
    const portfoliosTotalValue = portfolios.reduce((acc, portfolio) => acc + portfolio.weight, 0);
    setRecommendedTotalValue(setPrecision(portfoliosTotalValue, 2));
    fields.data.onChange({ target: targetGroups, recommended: recommendedGroups });
    fields.recommended.onChange(portfolios);
  }, [JSON.stringify(recommendedGroups)]);

  useEffect(() => {
    if (requiresInitialization) return;
    const portfolios = Object.values(targetGroups).reduce(
      (acc, portfolios) => [...acc, ...portfolios],
      []
    );
    const updatedRecommendedGroups = Object.entries(targetGroups).reduce(
      (acc, [groupId, portfolios]) => {
        const recommendedGroup = recommendedGroups[groupId] || [];
        const recommendedGroupPortfolios = setRecommendedGroupTargets(recommendedGroup, portfolios);
        return { ...acc, [groupId]: recommendedGroupPortfolios };
      },
      {}
    );
    setRecommendedGroups(updatedRecommendedGroups);
    fields.data.onChange({ target: targetGroups, recommended: updatedRecommendedGroups });
    fields.target.onChange(portfolios);

    // the value of `startingValue` is being normalized (see src/app/reducers/form.js).
    // In consequence, its `initialValue` is dirty (not pristine). We need to check if it
    // has been visited/tocuhed before overriding the value
    if (
      fields.startingValue.pristine ||
      !fields.startingValue.visited ||
      fields.startingValue.autofilled
    ) {
      const portfoliosTotalValue = portfolios.reduce((acc, portfolio) => acc + portfolio.amount, 0);
      fields.startingValue.autofill(portfoliosTotalValue || DEFAULT_STARTING_VALUE);
    }
  }, [JSON.stringify(targetGroups)]);

  return (
    <>
      <div className="step">
        <div className="step__container step__container--advanced" data-ips={isIPS}>
          <div className="recommended__label recommended__label--advanced">
            <label htmlFor="recommended">Units</label>
            <Toggle
              className="toggle-unit"
              icons={{
                checked: <span className="toggle-unit__icon">%</span>,
                unchecked: <span className="toggle-unit__icon">$</span>
              }}
              id="toggle-recommended-unit"
              onChange={toggleRecommendedUnit}
              checked={!!fields.recommendedWithPercentages.value}
            />
          </div>

          {groups.map(groupId => (
            <ProposalFormGroup
              canRemoveGroup={groups.length > 1}
              fields={fields}
              handleRecommendedChange={handleRecommendedChange(groupId)}
              handleTargetChange={handleTargetChange(groupId)}
              id={groupId}
              key={groupId}
              proposalType={proposalType}
              recommendedGroups={recommendedGroups}
              removeGroup={removeGroup}
              scope={scope}
              targetGroups={targetGroups}
              targetSuggestions={targetSuggestions}
            />
          ))}

          <div className="add-target-recommended-group">
            <button className="btn btn-transparent" onClick={addGroup} type="button">
              <i className="icon-add_item" /> <span>Add a group</span>
            </button>
          </div>
        </div>
      </div>

      <div className="step">
        <div className="step__container step__container--advanced-fees" data-ips={isIPS}>
          {!isIPS && (
            <ManagementFeeField
              field={fields.targetManagementFee}
              label="Target management fee *"
            />
          )}
          <div className="recommended-label">
            <label htmlFor="target-label">Customize target label (optional)</label>
            <VerboseErrorInput
              name="target-label"
              type="text"
              {...fields.targetLabel}
              placeholder="Target"
              className="form-control"
            />
          </div>

          {!isIPS && (
            <ManagementFeeField
              field={fields.recommendedManagementFee}
              label="Recommended management fee *"
            />
          )}
          <div className="recommended-label">
            <label htmlFor="recommended-label">Customize recommended label (optional)</label>
            <VerboseErrorInput
              name="model-label"
              type="text"
              {...fields.recommendedLabel}
              placeholder="Model"
              className="form-control"
            />
          </div>
        </div>
      </div>
    </>
  );
};

ProposalFormAdvancedVersion.defaultProps = {
  fields: {}
};

ProposalFormAdvancedVersion.propTypes = {
  fields: PropTypes.object,
  isIPS: PropTypes.bool.isRequired,
  onTargetChange: PropTypes.func.isRequired,
  proposalType: PropTypes.string.isRequired,
  scope: PropTypes.array.isRequired,
  setRecommendedTotalValue: PropTypes.func.isRequired,
  targetSuggestions: PropTypes.array.isRequired
};

export default ProposalFormAdvancedVersion;
