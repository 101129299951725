/* global STANDARD_DATE_FORMAT */
import _ from 'lodash';
import moment from 'moment';
import { getNormalizedPercentage, setPrecision } from 'utils/utils';

export const DEFAULT_STARTING_VALUE = 1000000;
export const DEFAULT_YEARLY_WITHDRAWAL_RATE = 5;

export const MIN_START_DATE = moment('1990-01-01').toDate();

export const MAX_START_DATE = moment().subtract(9, 'months').toDate();
export const DEFAULT_START_DATE = moment()
  .subtract(5, 'years')
  .startOf('year')
  .format(STANDARD_DATE_FORMAT);
export const DEFAULT_END_DATE = moment()
  .subtract(1, 'months')
  .endOf('month')
  .format(STANDARD_DATE_FORMAT);

export const LAST_DAY_OF_MONTH = moment(1, 'months').endOf('month');

export const DEFAULT_IPS_BENCHMARK = [{ id: 3279, ticker: 'ACWI', weight: 100 }];

export const toFloatPrecision = (number, decimalPlaces = 2) =>
  Number(number.toFixed(decimalPlaces));

export const portfoliosToSuggestions = (portfolios, type, belongsToHouseholdGroup = false) =>
  portfolios.map(p => {
    const portfolio = {
      belongsToHouseholdGroup,
      isBenchmark: p.is_benchmark,
      isStrategy: p.is_strategy,
      label: p.display_name || p.name,
      options: [],
      score: p.prism_overall,
      type,
      value: p.id
    };
    if (p?.value) portfolio.amount = toFloatPrecision(p.value);
    if (p?.target_object_ids) portfolio.targetObjectIds = p.target_object_ids;
    return portfolio;
  });

export const suggestionsToPortfolios = (suggestions, withWeight = true, withAmount = false) =>
  suggestions.map(s => {
    const portfolio = { id: s.value, type: s.type };
    if (withWeight && s?.weight) portfolio.weight = toFloatPrecision(s.weight);
    if (withAmount && s?.amount) portfolio.amount = toFloatPrecision(s.amount);
    if (s?.targetContentType) portfolio.target_content_type = s.targetContentType;
    if (s?.targetObjectIds) portfolio.target_object_ids = s.targetObjectIds;
    return portfolio;
  });

export const securitiesToSuggestions = securities =>
  securities.map(s => {
    const suggestion = {
      value: s.id,
      label: s.ticker === s.ticker_name ? s.ticker : `${s.ticker} - ${s.ticker_name}`,
      score: s.prism_overall,
      type: 'security'
    };
    if (s.weight) suggestion.weight = toFloatPrecision(s.weight);
    return suggestion;
  });

const accountToSuggestion = portfolio => ({
  amount: toFloatPrecision(portfolio.value),
  label: `${_.truncate(portfolio.investor_name, { length: 20 })} ${'\u2192'} ${portfolio.name}`,
  score: portfolio.prism_overall,
  type: 'account',
  value: portfolio.id
});

export const accountsToSuggestions = (portfolios, target) => {
  const [sources, others] = _.partition(portfolios, p => p.id === target.source);
  return [
    {
      label: 'Portfolio Source Account',
      options: sources.map(accountToSuggestion)
    },
    {
      label: 'All Accounts',
      options: others.map(accountToSuggestion)
    }
  ];
};

export const handleStartingValueChange =
  (startingValue, yearlyWithdrawalAmount, yearlyWithdrawalRate) => event => {
    const startValue = Number((event.target.value || '0').replace(/,/g, ''));
    const rate = Number(yearlyWithdrawalRate.value);
    const amount =
      Number.isNaN(startValue) || Number.isNaN(rate) ? '0' : ((startValue * rate) / 100).toString();

    yearlyWithdrawalAmount.onChange(amount);
    startingValue.onChange(event);
  };

export const handleYearlyWithdrawalAmountChange =
  (startingValue, yearlyWithdrawalAmount, yearlyWithdrawalRate) => event => {
    const amount = Number((event.target.value || '0').replace(/,/g, ''));
    const startValue = Number((startingValue.value || '0').replace(/,/g, ''));
    const rate =
      Number.isNaN(amount) || Number.isNaN(startValue)
        ? '0'
        : ((amount / startValue) * 100).toFixed(2);

    yearlyWithdrawalRate.onChange(rate);
    yearlyWithdrawalAmount.onChange(event);
  };

export const handleYearlyWithdrawalRateChange =
  (startingValue, yearlyWithdrawalAmount, yearlyWithdrawalRate) => event => {
    const startValue = Number((startingValue || '0').value.replace(/,/g, ''));
    const rate = setPrecision(Number(event.target.value), 2);
    const amount =
      Number.isNaN(startValue) || Number.isNaN(rate) ? '0' : ((startValue * rate) / 100).toString();

    yearlyWithdrawalAmount.onChange(amount);
    yearlyWithdrawalRate.onChange(event);
  };

export const normalizeProposalPercentageValues = values => {
  // normalize the management fees or remove them
  if (values.targetManagementFee)
    values.targetManagementFee = getNormalizedPercentage(values.targetManagementFee);
  else delete values.targetManagementFee;
  if (values.recommendedManagementFee)
    values.recommendedManagementFee = getNormalizedPercentage(values.recommendedManagementFee);
  else delete values.recommendedManagementFee;
  if (values.benchmarkManagementFee)
    values.benchmarkManagementFee = getNormalizedPercentage(values.benchmarkManagementFee);
  else delete values.benchmarkManagementFee;

  // normalize the yearly withdrawal rate or remove it
  if (values.yearlyWithdrawalRate)
    values.yearlyWithdrawalRate = getNormalizedPercentage(values.yearlyWithdrawalRate);
  else delete values.yearlyWithdrawalRate;
};

/**
 * Converts the amounts of source portfolios to percentages (weights).
 *
 * @param {Array} sourcePortfolios - An array of objects representing portfolios with weights.
 * @returns {Array} - An array of portfolios with weights converted to percentages.
 */
export const convertPortfoliosAmountsToPercentages = sourcePortfolios => {
  // get the total portfolios amount
  const totalAmount = sourcePortfolios.reduce((acc, p) => acc + p.weight, 0);

  // calculate and round percentages to two decimal places
  const portfolios = sourcePortfolios.map(p => {
    const weight = toFloatPrecision(Math.round((p.weight / totalAmount) * 10000) / 100);
    return { ...p, amount: p.weight, weight };
  });

  // adjust one of the percentages to ensure that the total sum is 100%
  const totalPercentage = toFloatPrecision(portfolios.reduce((acc, p) => acc + p.weight, 0));
  const adjustment = toFloatPrecision(100 - totalPercentage);
  portfolios[portfolios.length - 1].weight = toFloatPrecision(
    portfolios[portfolios.length - 1].weight + adjustment
  );

  return portfolios;
};

const normalizeSuggestions = suggestions => {
  if (!suggestions || !Array.isArray(suggestions)) return [];

  return suggestions.reduce((result, parentSuggestion) => {
    if (!parentSuggestion.options || !Array.isArray(parentSuggestion.options)) return result;

    parentSuggestion.options.forEach(childOption => {
      // if childOption's suggestions are empty, add the childOption itself;
      // otherwise, add all of childOption's suggestions
      if (_.isEmpty(childOption.suggestions)) result.push(childOption);
      else result.push(...childOption.suggestions);
    });

    return result;
  }, []);
};

const getSuggestionsDefault = (suggestions, groupDetails) => {
  const options = normalizeSuggestions(suggestions);
  return groupDetails
    .map(group => {
      const option = options.find(o => o.value === group.id);
      if (group?.target_content_type) option.targetContentType = group.target_content_type;
      if (group?.target_object_ids) option.targetObjectIds = group.target_object_ids;
      return { ...option, weight: group.weight };
    })
    .filter(g => Number.isFinite(g.value));
};

export const getPortfolioSuggestionsDefault = (proposal, suggestions, type) => {
  const MAX_PERCENTAGE = 100;
  const TARGET_TYPE = 'target';

  const withPercentages = type === TARGET_TYPE || proposal[`${type}_with_percentages`];
  const totalValue = withPercentages ? MAX_PERCENTAGE : proposal[`${type}_total_value`];
  const sourceGroupDetails = proposal[type].group_details;

  let groupDetails;
  if (sourceGroupDetails)
    groupDetails = withPercentages
      ? sourceGroupDetails
      : sourceGroupDetails.map(g => ({ ...g, weight: g.amount }));

  const suggestionsDefault = groupDetails
    ? getSuggestionsDefault(suggestions, groupDetails)
    : getSuggestionsDefault(suggestions, [{ id: proposal[type].id, weight: totalValue }]);

  return { totalValue, suggestionsDefault };
};
