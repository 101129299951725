/* eslint-disable react/no-array-index-key */
import cn from 'classnames';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formatMoney, formatPercentage } from 'utils/utils';
import './styles.scss';
import {
  RECOMMENDED_TYPE,
  TARGET_TYPE,
  getEntitiesFromProposal,
  getProcessedMatchingModels
} from './utils';

const MatchingModelsChart = ({ proposal }) => {
  const accounts = getEntitiesFromProposal(proposal, TARGET_TYPE);
  const models = getEntitiesFromProposal(proposal, RECOMMENDED_TYPE);

  const hasModels = !_.isEmpty(models);

  const targetName = proposal?.target_label || '';
  const recommendedName = proposal?.recommended_label || '';

  const withPercentages = proposal.recommended_with_percentages;
  const withTargetBreakdown = proposal.target_breakdown;

  const {
    accountsOverallScore,
    accountsTotalAmount,
    modelsOverallScore,
    modelsTotalValue,
    groups
  } = getProcessedMatchingModels(accounts, models, proposal, withPercentages, withTargetBreakdown);

  const renderGroupRows = (elements, type, maxNumberOfElements) =>
    Array.from(Array(maxNumberOfElements)).map((e, i) => {
      const element = elements[i];

      if (element && (type === TARGET_TYPE || type === RECOMMENDED_TYPE)) {
        const { amount, display_name: name, prism_overall: score, weight } = element;
        return (
          <Fragment key={`${name.toLowerCase().replace(/\s/g, '-')}-${score}-${amount}-${weight}`}>
            <div className="content__score-with-label">
              <ScoreBubble score={score} /> <p>{name}</p>
            </div>
            <div className="content__amount">{formatMoney(amount)}</div>
            <div className="content__weight">{formatPercentage(weight, 100, 2)}</div>
          </Fragment>
        );
      }

      return (
        <Fragment key={`empty-${type}-${i}`}>
          <div className="content__score-with-label" />
          <div className="content__amount" />
          <div className="content__weight" />
        </Fragment>
      );
    });

  const renderTotalRow = (label, overall, amount, weight) => (
    <>
      <div className="content__score-with-label">
        <ScoreBubble score={overall} /> <p>{label}</p>
      </div>
      <div className="content__amount">{amount}</div>
      <div className="content__weight">{weight}</div>
    </>
  );

  return (
    <div id="matching-models-chart" className={cn({ 'no-recommended': !hasModels })}>
      <div className="matching-models-chart__header">{targetName || 'Current portfolio'}</div>
      {hasModels && (
        <div className="matching-models-chart__header header--blue">
          {recommendedName || 'Suggested model'}
        </div>
      )}

      {groups.map(({ accounts, models, maxNumberOfElements }, idx, arr) => (
        <div
          className={cn('matching-models-chart__group', {
            'group--last': arr.length - 1 === idx
          })}
        >
          <div className="matching-models-chart__content">
            {renderGroupRows(accounts, TARGET_TYPE, maxNumberOfElements)}
          </div>
          {hasModels && (
            <div className="matching-models-chart__content">
              {renderGroupRows(models, RECOMMENDED_TYPE, maxNumberOfElements)}
            </div>
          )}
        </div>
      ))}

      <div className="matching-models-chart__total">
        {renderTotalRow('Total', accountsOverallScore, formatMoney(accountsTotalAmount), '100%')}
      </div>
      {hasModels && (
        <div className="matching-models-chart__total total--blue">
          {renderTotalRow(
            'Overall',
            modelsOverallScore,
            formatMoney(withPercentages ? accountsTotalAmount : modelsTotalValue),
            '100%'
          )}
        </div>
      )}
    </div>
  );
};

MatchingModelsChart.propTypes = {
  proposal: PropTypes.object.isRequired
};

export default MatchingModelsChart;
